import React from "react"

const ListItem = ({title, description, icon}) => (
	<div className="mb-4 md:mt-0">
		<div className="flex">
		    <div className="flex-shrink-0">
		      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-highlight-500 text-white">
		        {icon}
		      </div>
		    </div>
		    <div className="ml-4">
		      <h5 className="text-lg leading-6 font-medium text-gray-900">{title}</h5>
		      <p className="mt-2 text-base leading-6 text-gray-500">
		        {description}
		      </p>
		    </div>
	  </div>
	</div>
)

export default ListItem;