import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import FeaturePararaph from '../components/feature-paragraph'
import {AbaxElectricalTestimonial} from '../components/testimonial';

import sageLogoSrc from '../images/sage-logo.svg';
import fileSyncSrc from '../images/file-sync.svg';

import FreeTrialButton from '../components/free-trial-button';

import ListItem from '../components/list-item';


const Sage = () => (
  <Layout>
    <SEO 
    	title="Sage Timesheets Payroll Integration"
    	description="Timesheets compatible with Sage payroll software including 50cloud, Sage 200cloud and more."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Seamless integration with
			            <br />
			             <span style={{ color: '#00D03E' }}>Sage Payroll</span>
			          </h2>

			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Sage is a hugely popular UK payroll software that is used by companies far and wide. TimeKeeper fully supports integrating with Sage in just a few clicks!
			          </p>
			          <FreeTrialButton />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <a href="https://www.sage.com" className="relative block flex justify-center w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-1/2" src={sageLogoSrc} alt="Sage Logo" />
			            </a>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				     <LeftFeature 
				    	sectionTitle="Sage compatible integration" 
				    	title="How does the integration work?" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Sage has a mixture of desktop software and cloud software and is our most requested integration. Therefore at TimeKeeper
						          we're committed to ensuring we have the best Sage integration support possible!
						        </FeaturePararaph>
						        <div className="mt-8">
							        <ListItem
							          	title="Does it work with Sage 50cloud and Sage 200cloud?"
							          	description="Yes! Whenever you run your timesheets in TimeKeeper you can specify an export format of Sage 50. Then all you have to do is import this file into the Sage software and you're done!"
							           	icon={
							           		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" fillRule="evenodd"></path></svg>
							           	}
							           />
							           <ListItem
							          	title="Does it work with Sage One?"
							          	description="As of May 2020, not currently as Sage One does not have a timesheets integration mechanism as of yet. We promise to integrate as soon as possible!"
							          	icon={
							           		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path d="M6 13a4 4 0 010-8 4 4 0 118 0 4 4 0 010 8h-3V8a1 1 0 10-2 0v5H6zM9 13h2v2.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L9 15.586V13z"></path></svg>
							           	}
							           />
							           <ListItem
							          	title="Can you help us set this up?"
							          	description="Yes - we will jump on a call with you free of charge to set up your integration between TimeKeeper and Sage as well as answer any queries you may have."
							          	icon={
							          		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clipRule="evenodd" fillRule="evenodd"></path></svg>
							           	}
							           />
							    </div>
						    </div>
				    	}
				    	imgSrc={fileSyncSrc}
				    	imgAlt="Graphic showing files syncing"
				    	 />
				</div>
				<AbaxElectricalTestimonial />
				<FreeTrial 
					part1={<span>Sick of manually typing timesheets <br /> into Sage Payroll?</span>} 
				/>
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default Sage;
